import React from 'react';
import { graphql } from 'gatsby';
import SanityBlockContent from '@sanity/block-content-to-react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { getGatsbyImageData } from 'gatsby-source-sanity';
import Head from '../components/head';
import Layout from '../components/layout';
import Form from '../components/form';

const sanityConfig = {
  projectId: '80jisvar',
  dataset: 'production',
};

const serializers = {
  types: {
    imgBlock: ({ node }) => {
      const gatsbyImageData = getGatsbyImageData(node.asset._id, {}, sanityConfig);
      return <GatsbyImage image={gatsbyImageData} alt="" placeholder="dominantColor" className="mb-6" />;
    },
  },
};

export default function SinglePage({ data: { page } }) {
  return (
    <Layout>
      <Head title={page.headline} />
      <div className="max-w-screen-lg m-auto sm:animate-fade-content-page">
        <h1>
          <span aria-hidden="true" />
          {page.headline}
        </h1>
        <div className="grid sm:grid-cols-2 gap-4 contact">
          <SanityBlockContent
            renderContainerOnSingleChild
            blocks={page._rawContent}
            serializers={serializers}
            className="sm:text-lg sm:pr-8"
          />
          <div className="bg-gray-100 p-4 sm:p-8 rounded">
            <Form />
          </div>
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query {
    page: sanityPage(id: { eq: "-1096a87c-c32f-584e-9c52-dcd6b0bdf792" }) {
      _rawContent(resolveReferences: { maxDepth: 10 })
      headline
    }
  }
`;
